<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center align-center">
        <v-col cols='12'>
          <v-card-title >
            2. Общие правила при эксплуатации электроустановок, электросетей, электрооборудования
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-card-title class="red--text">
            ЗАПРЕЩЕНО:
          </v-card-title>
          <v-card-text class="title font-weight-light">
            Загромождать материалами и оборудованием электротехнические установки, электроаппаратуру и подступы к ним.
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-card class=" py-5">
            <v-tabs
            color="red"
            height="100%"
            class="d-flex justify-center"
            v-model="tab"
            >
              <v-tab class="pa-10">
                <v-img
                  lazy-src="img/material/motherboard.svg"
                  max-width="100"
                  src="img/material/motherboard.svg"
                  class="ma-1 pa-5"
                ></v-img>
              </v-tab>
              <v-tab>
                <v-img
                  lazy-src="img/material/cleaning-products.svg"
                  max-width="100"
                  src="img/material/cleaning-products.svg"
                  class="ma-5 pa-5"
                ></v-img>
              </v-tab>
              <v-tab>
                <v-img
                  lazy-src="img/material/room-heater.svg"
                  max-width="100"
                  src="img/material/room-heater.svg"
                  class="ma-5"
                ></v-img>
              </v-tab>
              <v-tab>
                <v-img
                  lazy-src="img/material/evacuation-plan.svg"
                  max-width="100"
                  src="img/material/evacuation-plan.svg"
                  class="ma-5"
                ></v-img>
              </v-tab>
              <v-tab>
                <v-img
                  lazy-src="img/material/no-flash.svg"
                  max-width="100"
                  src="img/material/no-flash.svg"
                  class="ma-5"
                ></v-img>
              </v-tab>
              <v-tab>
                <v-img
                  lazy-src="img/material/tool-box.svg"
                  max-width="100"
                  src="img/material/tool-box.svg"
                  class="ma-5"
                ></v-img>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card-text class="title pa-10 font-weight-light">
                  Защита электроустановок установок предохранительными устройствами от короткого замыкания. <br> Плавкие вставки предохранителей должны быть с клеймом о номинале тока.
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="title pa-10 font-weight-light">
                Очистка от горючей пыли электротехнических установок, электродвигателей, светильников, электропроводки и распределительных устройств <span class="red white--text">1 раз в месяц</span> , в помещениях с большим пылевыделением - <span class="red white--text">1 раз в неделю</span>.
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                 <v-card-text class="title pa-10 font-weight-light">
                Использование дополнительных токоприемников (двигатели, электронагревательные приборы и т.п.) <span class="red white--text">только с ведома ответственного лица и с учетом допустимой нагрузки на электросеть.</span>
                 </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="title pa-10 font-weight-light">
                  Исправные и включенные объемные сомосветящиеся знаки ПБ на путях эвакуации (в том числе световые указатели "Эвакуационный (запасный) выход", "Дверь эвакуационного выхода"). <br> Эвакуационное освещение должно включаться автоматически при прекращении электропитания рабочего освещения.
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="title pa-10 font-weight-light">
                  Обесточивание:
                  <ul>
                    <li>электроосветительной и силовой электросети в производственных помещениях, которые по окончании работы закрываются;</li>
                    <li>электроустановок и бытовых электроприборов в помещениях, в которых по окончании рабочего времени отсутствует дежурный персонал.</li>
                  </ul>
                  <p><span class="red white--text">Исключение: </span>дежурное освещение, установки пожаротушения и противопожарного водоснабжения, пожарной и охранно-пожарной сигнализации, также установки и изделия, для которых это предусмотренно требованиями инструкции по эксплуатации.</p>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card-text class="title pa-10 font-weight-light">
                  <p>Немедленное устранение нисправностей (искрение, короткое замыкание, нагрев изоляции проводов и кабелей) дежурными электромонтерами.</p>
                  <p>При невозможности немедленного устранения <span class="red white--text">электросеть следует отключить</span>  до приведения ее в пожаробезопасное состояние.</p>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col>
          <Hint></Hint>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
